import React from "react";
import Toast from "react-bootstrap/Toast";

const ToastMessage = ({ handleClose, show, className }) => {
  // const [show, setShow] = useState(false);

  return (
    <Toast
      onClose={handleClose}
      show={show}
      delay={3000}
      autohide
      animation
      className={className}
    >
      <Toast.Header closeButton={false} closeLabel="">
        <strong className="mr-auto">Message Sent</strong>
      </Toast.Header>
      <Toast.Body>Woohoo, you sent Hector a message!</Toast.Body>
    </Toast>
  );
};

export default ToastMessage;
