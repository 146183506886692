import { create } from "apisauce";
require("dotenv").config();

const apiUrl = "https://hector-chatbot-7bed76d86806.herokuapp.com/"; // ! production
// const apiUrl = "http://127.0.0.1:8000/"; // ! LocalHost copy the url your are running locally

const apiClient = create({
  baseURL: apiUrl,
});

apiClient.setHeader(
  "Authorization",
  process.env.REACT_APP_CHATBOT_API_ACCESS_TOKEN
);
apiClient.setHeader("Content-Type", "application/json");

const post = apiClient.post;

apiClient.post = async (url, data, axiosConfig) => {
  // make sure to match default of the original post function
  const response = await post(url, data, axiosConfig);

  return response;
};

export default apiClient;
