import React from "react";
import { Form } from "react-bootstrap";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

const FormField = ({ name, label, maxLength = 255, className }) => {
  const {
    setFieldTouched,
    handleChange,
    touched,
    errors,
    values,
  } = useFormikContext();
  return (
    <Form.Group style={{ paddingBottom: 20 }} controlId={`form${name}`}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        as="textarea"
        rows={5}
        onChange={handleChange}
        onBlur={() => setFieldTouched(name)}
        value={values[name]}
        name={name}
        isInvalid={touched[name] && errors[name]}
        spellCheck
        maxLength={maxLength}
        autoCapitalize="sentences"
        style={{ resize: "none" }}
        className={className}
      />
    </Form.Group>
  );
};

export default FormField;

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormField.defaultProps = {
  label: null,
  width: "100%",
};
