import { Button } from "react-bootstrap";
import React, { useRef, useEffect, useState } from "react";
import { ChevronUp, ChevronDown } from "react-feather";
import "./styles.css";

const MAX_POSSIBLE_HEIGHT = 1000;

const Collapse = ({ maxHeight, children }) => {
  const ref = useRef();
  const [shouldShowExpand, setShouldShowExpand] = useState(false);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (ref.current.scrollHeight > maxHeight) {
      setShouldShowExpand(true);
      setExpanded(false);
    }
  }, [maxHeight]);

  return (
    <div className="collapse-module" ref={ref}>
      <div
        className="inner"
        style={{ maxHeight: expanded ? MAX_POSSIBLE_HEIGHT : maxHeight }}
      >
        {children}
      </div>
      {shouldShowExpand && (
        <div className="button-container">
          <Button
            className="button"
            onClick={() => {
              setExpanded(!expanded);
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {expanded ? <ChevronUp size={25} /> : <ChevronDown size={25} />}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Collapse;
