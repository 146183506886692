import React from "react";
import Image from "react-bootstrap/Image";
// import Collapse from "../Custom/Collapse";

import "./styles.css";

function AboutMe() {
  return (
    <div id="aboutme" className="container about-me">
      <h1 className="title">Personal Bio</h1>
      <div className="row">
        <div className="col-md-6">
          {/* <Collapse maxHeight={615}> */}
          <p className="body">
            Born to Dominican immigrants, Hector Valerio is a first generation
            Dominican-American who was born in New York City. Hector is an
            experienced technical product manager, entrepreneur, investor and
            inventor. Hector truly has a passion for learning, innovating, and
            mastering any skill that is introduced to him.
          </p>
          <p className="body">
            While spending most of his childhood in a small town in the
            Dominican Republic, he later returned to New York where he attained
            a Bachelor’s of Science degree in Electrical Engineering from The
            City College of New York. Upon graduation, Hector was offered the
            opportunity to begin his professional career at General Motors in
            Detroit, Michigan. Hector accepted the role as a Test Engineer,
            which thus inspired him to further his education and achieve his
            Master’s of Engineering at the University of Michigan.
          </p>
          <p className="body">
            Hector is the owner and CEO of Repic, an app that was inspired by
            his own passions. Repic is an app that allows Real Estate lovers and
            investors to thoroughly analyze properties; he fully designed,
            developed, and deployed Repic from scratch. He is biased to action,
            resourceful and highly impactful. In his free time, Hector enjoys
            spending time with family, reading, and adventure in the great
            outdoors.
          </p>
          {/* </Collapse> */}
        </div>
        <div className="col-md-6 pt-2">
          <div className="row">
            <div className="col-12 media-container">
              <Image
                className="image"
                // src="https://res.cloudinary.com/desivddvc/image/upload/q_auto:eco/v1612902037/Panaromic_1_voc6q6.jpg"
                // src="https://res.cloudinary.com/desivddvc/image/upload/c_pad,b_gen_fill,w_592,h_182/v1612902037/Panaromic_1_voc6q6.jpg" // has AI generative
                src="https://res.cloudinary.com/desivddvc/image/upload/w_1184,h_364/v1612902037/Panaromic_1_voc6q6.jpg"
                alt="Arroyo Grande Republica Domiinicana"
                fluid
              />
            </div>
            <div className="col-3 media-container">
              <Image
                className="image"
                // src="https://res.cloudinary.com/desivddvc/image/upload/q_auto:eco/v1612965933/Picture_2_a8agbq.png"
                // src="https://res.cloudinary.com/desivddvc/image/upload/c_pad,b_gen_fill,w_142,h_190/v1612965933/Picture_2_a8agbq.png" // has AI generative
                src="https://res.cloudinary.com/desivddvc/image/upload/w_284,h_380/v1612965933/Picture_2_a8agbq.png"
                alt="Iglesia Corazon de Jesus Moca, Espaillat"
                fluid
              />
            </div>
            <div className="col-6 media-container">
              <video
                className="video"
                // src="https://res.cloudinary.com/desivddvc/video/upload/q_auto:eco/v1612980517/EnduroRideMohapac_fjcmia.mov"
                src="https://res.cloudinary.com/desivddvc/video/upload/w_292,h_164,q_auto:eco/v1612980517/EnduroRideMohapac_fjcmia.mov"
                autoPlay
                muted
                loop
                playsInline
              ></video>
            </div>
            <div className="col-3 media-container">
              <Image
                className="image"
                // src="https://res.cloudinary.com/desivddvc/image/upload/q_auto:eco/v1612901761/Picture_1_yelsh5.jpg"
                // src="https://res.cloudinary.com/desivddvc/image/upload/c_pad,b_gen_fill,w_142,h_190/v1612901761/Picture_1_yelsh5.jpg" // has AI generative
                src="https://res.cloudinary.com/desivddvc/image/upload/w_284,h_380/v1612901761/Picture_1_yelsh5.jpg"
                alt="Statue of Liberty NYC"
                fluid
              />
            </div>
            <div className="col-12 media-container">
              <Image
                className="image"
                // src="https://res.cloudinary.com/desivddvc/image/upload/q_auto:eco/v1612902036/Panaromic_2_c5bvmd.jpg"
                // src="https://res.cloudinary.com/desivddvc/image/upload/c_pad,b_gen_fill,w_592,h_182/v1612902036/Panaromic_2_c5bvmd.jpg" // has AI generative
                src="https://res.cloudinary.com/desivddvc/image/upload/w_1184,h_364/v1612902036/Panaromic_2_c5bvmd.jpg"
                alt="Arroyo Grande Moca Espaillat Valerio Family Origin"
                fluid
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
