import { useState } from "react";

export default function useApi(apiFunc) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const request = async (...args) => {
    setLoading(true);
    fetch(apiFunc(...args)).then((response) => {
      setLoading(false);
      setError(!response.ok); //compbine the statements
      setData(response.data);
      return response;
    });
  };

  return { request, data, error, loading };
}
