/* eslint-disable react/forbid-prop-types */
import React from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import PropTypes from "prop-types";

const Forms = ({ initialValues, onSubmit, validationSchema, children }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => <Form>{children}</Form>}
    </Formik>
  );
};

export default Forms;

Forms.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.object,
  children: PropTypes.object.isRequired,
};

Forms.defaultProps = {
  onSubmit: null,
  validationSchema: null,
};
