import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as Yup from "yup";

//* Custom Components
import { Forms, FormField, TextAreaField, SubmitButton } from "../Custom/forms";
import Toast from "../Custom/Toast";

//* API
import sendMailApi from "../../api/firebaseFunc/sendmail";

//* Hooks
import useApi from "../../hooks/useApi";

//* Styles
import "./styles.css";

import { techSkills } from "../../data/techskills";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(5).max(50).label("Name"),
  email: Yup.string().required().max(255).email().label("Email"),
  message: Yup.string().required().min(4).max(255).label("Message"),
});

const LetsTalk = () => {
  const [showToast, setShowToast] = useState(false);

  //* Define Apis
  const sendMail = useApi(sendMailApi.getSendMail);

  const handleSubmit = async (data, { resetForm }) => {
    await sendMail.request(data);
    resetForm();
    setShowToast(true);
  };

  return (
    <Container>
      <Row>
        <Col md={6} id="contact" className="lets-talk">
          <Row className="form">
            {showToast && (
              <Toast
                handleClose={() => setShowToast(false)}
                show={showToast}
                className="toast"
              />
            )}
            <Col md={12}>
              <h1 className="title">Let's Talk!</h1>
              <Row>
                <Forms
                  initialValues={{
                    name: "",
                    email: "",
                    message: "",
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  <Row>
                    <Col md={6}>
                      <FormField
                        name="name"
                        label="Name"
                        className="text-input"
                      />
                    </Col>
                    <Col md={6}>
                      <FormField
                        name="email"
                        label="Email"
                        className="text-input"
                      />
                    </Col>
                    <Col md={12}>
                      <TextAreaField
                        name="message"
                        label="Message"
                        className="textbox-input"
                      />
                    </Col>
                    <Col md={6}>
                      <SubmitButton title="Send Message" className="button" />
                    </Col>
                  </Row>
                </Forms>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md={6} id="skill" className="tech-skills">
          <Row>
            <h1 className="title">
              I have extensive knowledge of the following technologies
            </h1>
            {Object.entries(techSkills).map(([key, value]) => (
              <React.Fragment key={key}>
                <div className="subtitle">{key}</div>
                <div className="item">
                  {value.map((item) => (
                    <div key={item} className="body">
                      {item}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LetsTalk;
