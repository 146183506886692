// *SVG and PNGs
import verizon from "../assets/verizon.svg";
import generalMotors from "../assets/general-motors.svg";
import repic from "../assets/repic.svg";
import cleverDevices from "../assets/clever-devices.svg";
import yahoo from "../assets/yahoo.svg";

const experiences = [
  {
    id: 1,
    company: "Yahoo",
    src: yahoo,
    alt: "yahoo",
    title: "Sr. Product Manager",
    date: "2021 - 2023",
    subtitle: "American web services provider.",
    body: "Led the Mail Intelligence team, targeting Mail classification and extractions for the Yahoo Consumer Group. Prioritized customer-focused solutions by collaborating with Engineering, Data Science, Research, and Editorial teams. Leveraged Machine Learning to enhance mail intelligence from information retrieval to email classification. Coordinated with cross-functional teams to advance intelligence capabilities benefiting the Yahoo ecosystem. Established data-informed product designs and decision-making processes. Simplified complex projects into actionable milestones, aligning with broader platform goals. Collaborated with Privacy and Legal to uphold customer data rights and privacy.",
    deliverables: {
      projects: [
        {
          id: 1,
          title: "Subscriptions Manager",
          desc: "Successful execution of the crawl phase reaching an 80% customer satisfaction rate.",
        },
        {
          id: 2,
          title: "Bill Negotiation",
          desc: "Helped users negotiate their internet, cable, and phone bill (5% CTR).",
        },
        {
          id: 3,
          title: "Package Tracking Experience ",
          desc: "Drove a 15% increase in Retention, Days visits in past 31 days (DV31).",
        },
      ],
      processes: [
        {
          id: 1,
          title: "Deployment of Sequence-to-Sequence (Seq2Seq) ML model",
          desc: "Increase of 5% and 20% in precision and recall, respectively.",
        },
        {
          id: 2,
          title: "Recommendations Extractions",
          desc: "E-commerce daily average revenue increase of 20%.",
        },
        {
          id: 3,
          title: "Voice of the Customer (VOC) Platform ",
          desc: "35% opt-in rate to share data.",
        },
      ],
    },
  },
  {
    id: 2,
    company: "Verizon",
    src: verizon,
    alt: "verizon",
    title: "Product Manager",
    date: "2018 - 2021",
    subtitle: "American multinational telecommunications conglomerate.",
    body: "Key stakeholder in the transformative 'LoV' platform, enhancing point-of-sale and support interactions for customers and representatives. Collaborated with cross-functional and remote teams to ensure timely project delivery within budget constraints. Conducted focus groups with diverse stakeholders—from representatives to directors—to ascertain customer needs. Partnered with the program manager and business transformation team, utilizing metrics and data-driven insights to define challenges. Orchestrated the solution lifecycle, from feasibility and UX design to System Integration Testing (SIT), User Acceptance Testing (UAT), agent training, pilots, and final production rollout.",
    deliverables: {
      projects: [
        {
          id: 1,
          title: "Life on Verizon Live Review",
          desc: "Delivered from concept to launch in retail, telesales, and customer service. It reduced call volume by 10% and increased digital engagement by 10%.",
        },
        {
          id: 2,
          title: "One Verizon ID",
          desc: "Consolidated a single Log In ID for wireless and wireline customers.",
        },
        {
          id: 3,
          title: "Increase Attach Rate",
          desc: "Reached 90% Attach rate in our ordering flow.",
        },
        {
          id: 4,
          title: "Awarded Multiple Verizon Spotlight Awards",
          desc: "For delivering the best Customer Experience & The Innovator Award.",
        },
      ],
      processes: [
        {
          id: 1,
          title: "Ambassador of agile methodology",
          desc: "Successfully brought developers and leadership up to speed with the agile process.",
        },
        {
          id: 2,
          title: "Defined clear and concise product vision backlog",
          desc: "With three sprints in advance of development stories with unambiguous requirements in gherkin format for developers to bring to life.",
        },
        {
          id: 3,
          title: "Distinct eye for the intersection of MVP and customer value",
          desc: "Cognizant of Key KPI’s: Average handling time (AHT), Call Volume Reduction (CVR), Digital Engagement & Net Promoter Score (NPS).",
        },
      ],
    },
  },
  {
    id: 3,
    company: "Clever Device",
    src: cleverDevices,
    alt: "cleverDevices",
    title: "Product Manager",
    date: "2016-2018",
    subtitle:
      "Provider of technology that makes transit agencies smarter, safer, and more efficient.",
    body: "Product Owner of Bustime and Busware, key transit solutions targeting customer ridership and vehicle operations across iOS, Android, and responsive web platforms. Delivered precise directives for product initiatives. Led a matrixed team structure, coordinating five multi-disciplinary teams comprising six developers each. Steered sprint planning, product releases, requirements assessments, and enterprise planning. Curated and maintained essential product documentation, including release notes, presentations, roadmaps, and user stories.",
    deliverables: {
      projects: [
        {
          id: 1,
          title: "Disruption Management",
          desc: "Project focused on creating changes to current bus routes in mass transit by using a computer-aided dispatcher for the route in real-time, then sending it to the cloud.",
        },
        {
          id: 2,
          title: "Train Arrival Prediction Server (TAPS)",
          desc: "Product telling arrival time of trains (railway) to users by using an algorithmic software.",
        },
        {
          id: 3,
          title: "Simplified advanced dynamic scheduling",
          desc: "Developed disruption management’s “cancel, shift, add” to remove complicated steps.",
        },
      ],
      processes: [
        {
          id: 1,
          title: "Streamlined product deployment",
          desc: "By using Atlassian's JIRA software to manage products, create queries, and filter out deficiencies in the product backlog.",
        },
        {
          id: 2,
          title: "Story Sizing",
          desc: "Utilized Windows sticky notes in a collaborative WebEx session, enabling developers to size stories for each feature effectively in real-time.",
        },
        {
          id: 3,
          title: "BusWare Legacy Software Organization Project",
          desc: "Saved significant money by reducing outsourced developers and leveraging and sizing features already implemented to reduce 3-year software backlog.",
        },
      ],
    },
  },
  {
    id: 4,
    company: "General Motors",
    src: generalMotors,
    alt: "generalMotors",
    title: "BOM Family Owner",
    date: "2011-2016",
    subtitle:
      "American multinational corporation that designs, manufactures, markets, and distributes vehicles and vehicle parts.",
    body: "Product Manager of Intelligent Battery Sensor (IBS), oversaw the creation of component requirements and managed an indirect, matrixed team comprising four global IBS design release engineers (DRE). Engaged in gathering competitor technology innovation insights and facilitated weekly vendor meetings.",
    deliverables: {
      projects: [
        {
          id: 1,
          title: "Cut multimillion-dollar budget",
          desc: "by changing to a more cost-efficient (~20% less) component supplier with equal performance.",
        },
        {
          id: 2,
          title: "Awarded patent in 2011",
          desc: "Modular System and Method for Simulating Performance of Electrical Device. Patent No US 9,298,867.",
        },
        {
          id: 3,
          title: "Winner of team recognition award",
          desc: "For excellence in project delivery.",
        },
      ],
      processes: [
        {
          id: 1,
          title: "Delivered global operational efficiency",
          desc: "By working closely with employees based in Germany through weekly meetings.",
        },
        {
          id: 2,
          title:
            "Ensured component software met Stop/Start interface requirements",
          desc: "By defining the electric power Stop/Start system design for new vehicle applications from the ground up.",
        },
        {
          id: 3,
          title:
            "Developed energy storage and power switching technical specifications",
          desc: "Based upon system requirements.",
        },
      ],
    },
  },
  {
    id: 5,
    company: "Repic",
    src: repic,
    alt: "repic",
    title: "Founder & Developer",
    date: "2020 - present",
    subtitle:
      "Examine investment assets, evaluate cashflow & profit, and discover the best real estate passive income deals.",
    body: "Started and have been building a successful mobile application for real estate investors called Real Estate Passive Income Calculator (Repic).",
    deliverables: {
      projects: [
        {
          id: 1,
          title: "Test Driven Development (TDD) API",
          desc: "Backend in Node.JS framework with over 90% code coverage.",
        },
        {
          id: 2,
          title: "Multiplatform Application",
          desc: "Frontend in React Native Framework.",
        },
      ],
      processes: [
        {
          id: 1,
          title: "BugSnag implementation",
          desc: "To proactively capture defects with the application.",
        },
        {
          id: 2,
          title: "Google Analytics Implementation",
          desc: "To capture & analyze custom key performance metrics.",
        },
        {
          id: 3,
          title: "Google Ads Implementation",
          desc: "To grow acquisition with a 4% clickthrough rate.",
        },
        {
          id: 4,
          title: "Google Places API Integration",
          desc: "To make it super-fast and easy to assess properties.",
        },
      ],
    },
  },
];

export { experiences };
