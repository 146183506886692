import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./styles.css";

function CustomCard({
  src,
  cardTitle,
  cardText,
  onClick,
  alt,
  showButton = true,
}) {
  return (
    <Card className="container card-module">
      <div className="image-container">
        <Card.Img src={src} alt={alt} variant="top" className="image" />
      </div>
      <Card.Body>
        <Card.Title>{cardTitle}</Card.Title>
        <Card.Text>{cardText}</Card.Text>
        {showButton ? (
          <div className="button-container">
            <Button
              className="button"
              onClick={onClick}
              size="md"
              variant="outline-secondary"
              block
              onMouseDown={(e) => e.preventDefault()}
            >
              Explore My Role Details
            </Button>
          </div>
        ) : null}
      </Card.Body>
    </Card>
  );
}

export default CustomCard;
