/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Navbar, Nav } from "react-bootstrap";

// Style
import "./style.css";

function NavBar() {
  return (
    <Navbar collapseOnSelect className="navBar" expand="md" fixed="top">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Brand>
        <a className="brand" href="#">
          Héctor V.
        </a>
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#aboutme">Personal Bio</Nav.Link>
          <Nav.Link href="#experience">Experience</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
          <a
            href="https://drive.google.com/file/d/1NYy4sM6jYIYy8XRxyUke0r_bJjjEeVCE/view?usp=sharing"
            className="download"
            target="_blank"
            rel="noreferrer noopener"
          >
            Download Resume
          </a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
