import React from "react";
import Button from "react-bootstrap/Button";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

const SubmitButton = ({ title, color, textColor, isLoading = false }) => {
  const { handleSubmit, isValid } = useFormikContext();
  return (
    <Button
      size="lg"
      variant="outline-secondary"
      className="button"
      block
      disabled={!isValid}
      onClick={handleSubmit}
      onMouseDown={(e) => e.preventDefault()}
    >
      {title}
    </Button>
  );
};

export default SubmitButton;

SubmitButton.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
  isLoading: PropTypes.bool,
};

SubmitButton.defaultProps = {
  color: "primary",
  textColor: "white",
  isLoading: false,
};
