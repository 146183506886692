const techSkills = {
  "Operating Systems": ["Microsoft Windows", "Apple OSX", "Linux"],
  "Programming Languages": [
    "JavaScript",
    "HTML",
    "CSS",
    "Java",
    "C/C++",
    "MATLAB",
    "Python",
  ],
  "Software & Tools": [
    "G Suite",
    "MS Office",
    "Atlassian Tools",
    "Tableau",
    "Figma",
    "Google Analytics",
    "Admob",
    "Balsamiq",
    "Prism",
  ],
  "Dev Environments & Libraries": [
    "Visual Studio Code",
    "IntelliJ",
    "GitHub",
    "Postman",
    "Heroku",
    "NodeJS",
    "ReactJS",
    "NPM",
    "Xcode",
    "Jupyter",
  ],
  Databases: ["MongoDB (NoSQL)", "MySQL (SQL)", "Hive (HQL)"],
};

export { techSkills };
