/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

// * Styles
import "./style.css";

//* SVG
// import facebook from "../../assets/facebook.svg";
import github from "../../assets/github.svg";
import linkedin from "../../assets/linkedin.svg";

const Headline = () => {
  return (
    <div id="home" className="container headline">
      <div className="row mb-3 flex-column-reverse flex-md-row ">
        <div className="col-md-8 ">
          <div className="pb-3">
            <h1 className="title">
              Product Manager: Data-Driven Customer Solutions Specialist
            </h1>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <p className="body">
                Software engineering professional with demonstrated experience
                driving unprecedented results within competitive industries and
                in high-visibility positions. Well-versed in all aspects of
                feature development, product strategy, product roadmap, new
                feature definition, product vision execution, and meeting
                business goals. Adept in identifying the best Minimum
                Viable/Lovable Product. Proven proficiency improving bottom-line
                profitability while achieving the highest levels of customer
                satisfaction.
              </p>
            </div>
            {/* <div className="col-md-12 headline-social-content"> */}
            <div className="col-md-12">
              <div className="row">
                <div className="col-12 col-md-6 d-grid ">
                  <Button
                    size="lg"
                    variant="outline-secondary"
                    className="button"
                    block
                    as="a"
                    href="#contact"
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    Let's Talk
                  </Button>
                </div>
                <div className="col-6 col-md-4 social-container">
                  <a
                    href="https://www.linkedin.com/in/hectorvalerio"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={linkedin} className="svg" alt="linkedin" />
                  </a>
                  {/* <a
                    href="https://www.facebook.com/hector.valerio"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={facebook} className="svg" alt="facebook" />
                  </a> */}
                  <a
                    href="https://github.com/hvaleri0"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={github} className="svg" alt="github" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <Image
            className="image"
            src="https://res.cloudinary.com/desivddvc/image/upload/w_512,h_512,q_auto:eco/v1611780763/Brand-Image_gn9rhg.png"
            alt="Brand Image"
            loading="lazy"
            roundedCircle
          />
        </div>
      </div>
    </div>
  );
};

export default Headline;
