import HttpsRedirect from "react-https-redirect";
import "./App.css";
import Headline from "./components/Headline";
import NavBar from "./components/NavBar";
import CoreComp from "./components/CoreComp";
import PreviousExperience from "./components/PreviousExperience";
import AboutMe from "./components/AboutMe";
import LetsTalk from "./components/LetsTalk";
import Footer from "./components/Footer";
import ChatBot from "./components/Custom/ChatBot";

const enableChatbot = process.env.REACT_APP_ENABLE_CHATBOT === "true";

function App() {
  return (
    <HttpsRedirect>
      <div className="safe-area">
        <NavBar />
        <Headline />
        <CoreComp />
        <PreviousExperience />
        <AboutMe />
        <LetsTalk />
        <Footer />
        {enableChatbot && <ChatBot />}
      </div>
    </HttpsRedirect>
  );
}

// ? Check for memory usage.
// const memoryEntries = performance.memory;
// console.log("Memory Usage:", memoryEntries);
export default App;
