import { create } from "apisauce";

const apiUrl = "https://us-central1-portfolio-webapp-19cff.cloudfunctions.net/";

const apiClient = create({
  baseURL: apiUrl,
});

const get = apiClient.get;

apiClient.get = async (url, params, axiosConfig) => {
  // make sure to match default of the original get function
  const response = await get(url, params, axiosConfig);

  if (response.ok) {
    return response;
  }
};

export default apiClient;
