/* eslint-disable import/no-anonymous-default-export */
import client from "./client";
const apiEndPoint = "/sendmail";

const getSendMail = (params) => {
  client.get(apiEndPoint, params);
};

export default {
  getSendMail,
};
