const firebaseConfig = {
  apiKey: "AIzaSyDpUs-D0qOAGqgeU77KTFwOAJe5eUWHMbQ",
  authDomain: "portfolio-webapp-19cff.firebaseapp.com",
  projectId: "portfolio-webapp-19cff",
  storageBucket: "portfolio-webapp-19cff.appspot.com",
  messagingSenderId: "859388120734",
  appId: "1:859388120734:web:6846d84c4043c31a97f58f",
  measurementId: "G-18J10JZJZ6",
};

export default firebaseConfig;
