import React from "react";
import Collapse from "../Custom/Collapse";

//Style
import "./style.css";

//data
import { competencies } from "../../data/corecomp.js";

function CoreComp() {
  return (
    <Collapse maxHeight={410}>
      <div className=" corecomp">
        <div className="row justify-content-center">
          <h1 className="title">Core Competencies</h1>
          {competencies.map((competency) => (
            <div key={competency.id} className="col-md-3 item">
              <div className="body">{competency.name}</div>
            </div>
          ))}
        </div>
      </div>
    </Collapse>
  );
}

export default CoreComp;
