import React, { useState } from "react";

import Modal from "../Custom/Modal";
import Card from "../Custom/Card";

// * Data
import { experiences } from "../../data/experiences";

// * Style
import "./styles.css";

function PreviousExperience() {
  const [modalShow, setModalShow] = useState(false);
  const [selection, setSelection] = useState(0);
  return (
    <>
      <div id="experience" className="container previous-experience">
        <h1 className="title">Professional Experience</h1>
        <div className="row justify-content-center mb-4">
          {experiences.map((experience, index) => (
            <div key={`experience-${experience.id}`} className="col-md-4">
              <Card
                src={experience.src}
                alt={experience.alt}
                cardTitle={experience.title}
                cardText={experience.date}
                onClick={() => {
                  setSelection(index);
                  setModalShow(true);
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={experiences[selection]}
      />
    </>
  );
}

export default PreviousExperience;
