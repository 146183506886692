const competencies = [
  {
    id: 1,
    name: "Customer Focused",
  },
  {
    id: 2,
    name: "Cross-Functional Collaboration",
  },
  {
    id: 3,
    name: "New Product Development",
  },
  {
    id: 4,
    name: "Experience Design",
  },
  {
    id: 5,
    name: "Objectives and Key Results",
  },
  {
    id: 6,
    name: "Written Narratives",
  },
  {
    id: 7,
    name: "Product Discovery & Delivery",
  },
  {
    id: 8,
    name: "Product Lifecycle Management",
  },
  {
    id: 9,
    name: "Strategic Product Roadmap(s)",
  },
  {
    id: 10,
    name: "Product Design",
  },
  {
    id: 11,

    name: "Design Thinking",
  },
  {
    id: 12,
    name: "Agile/Scrum Methodology",
  },
  {
    id: 13,
    name: "Team Engagement",
  },
  {
    id: 14,
    name: "Bilingual: English/Spanish",
  },
  {
    id: 15,
    name: "Relationship Management",
  },
  {
    id: 16,
    name: "SMART Goals",
  },
  {
    id: 17,
    name: "Machine Learning",
  },
  {
    id: 18,
    name: "Product Requirements",
  },
  {
    id: 19,
    name: "Process Improvement",
  },
  {
    id: 20,
    name: "Data Analysis",
  },
];

export { competencies };
