import React from "react";
import Modal from "react-bootstrap/Modal";
import { Target } from "react-feather";

import colors from "../../../config/colors";

import "./styles.css";

const RepicGoogleStore = () => (
  <div>
    <a href="https://play.google.com/store/apps/details?id=io.repic.repic&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <img
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        className="download-image-google"
      />
    </a>
  </div>
);

const RepicAppleStore = () => (
  <div className="p-3">
    <a href="https://apps.apple.com/us/app/repic/id1482457506?itsct=apps_box&amp;itscg=30200">
      <img
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?releaseDate=1585008000&h=9ad8176a64ac7f8cdc010828efb0b52d"
        alt="Download on the App Store"
        className="download-image-apple"
      />
    </a>
  </div>
);

const Items = ({ deliverables, type }) => (
  <>
    <div className="col-md-12 subtitle2">{type}</div>
    {deliverables.map((deliverable) => (
      <div key={deliverable.id}>
        <div className="row mb-3">
          <div className="col-2 icon-container">
            <Target size={20} color={colors.secondary} />
          </div>
          <div className="col-10">
            <div className="row">
              <div className="col-12 subtitle3">{deliverable.title}</div>
              <div className="col-12 body2">{deliverable.desc}</div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

function CustomModal({ show, onHide, data }) {
  const { src, alt, title, date, subtitle, body, deliverables, company } = data;
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName="content"
    >
      <Modal.Header closeButton closeLabel="">
        <Modal.Title id="contained-modal-title-vcenter">
          <img src={src} className="modal-image" alt={alt} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container custom-modal">
          <div className="row mb-3">
            <div className="col-md-8">
              <div className="pb-3 title">{title}</div>
              <div className="row">
                <div className="col-md-12 date">{date}</div>
                <div className="col-md-12 subtitle">{subtitle}</div>
                <div className="col-md-12 body">{body}</div>
                {company === "Repic" ? (
                  <div className="col-md-12">
                    <RepicAppleStore />
                    <RepicGoogleStore />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-4 deliverables">
              <div className="pb-3 title2">DELIVERABLES</div>
              <div className="row">
                {deliverables?.projects && (
                  <Items
                    deliverables={deliverables.projects}
                    type={"PROJECT DELIVERY"}
                  />
                )}
                {deliverables?.processes && (
                  <Items
                    deliverables={deliverables.processes}
                    type={"PROCESS IMPROVEMENT"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
