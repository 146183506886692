// ChatBot.js
import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { ArrowUp, PersonCircle, Robot, XCircle } from "react-bootstrap-icons";
import Image from "react-bootstrap/Image";

// * Styles
import "./styles.css";

// * SVG
import chatIcon from "../../../assets/chatbot.svg";

//* API
import askQuestion from "../../../api/chatGpt/askQuestion";

// * Constants
// const TEST_ANSWER = "thanks for the question this a generic answer to test experience";
const TYPING_SPEED = 100; // in milliseconds
const ERROR_ANSWER = "An error occurred while processing your request.";

const ChatBot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      text: "Hello! Ask about my career. Note: May have discrepancies. For clarity, contact directly.",
      sender: "bot",
    },
  ]);
  const [input, setInput] = useState("");
  const [isBotTyping, setIsBotTyping] = useState(false);

  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // ? Handle API Response without Typing sim
  // const handleApiResponse = (response) => {
  //   // Remove the loadin message and add the new message in a single operation to avoid extra re-renders
  //   setMessages((prevMessages) => {
  //     let updatedMessages = [...prevMessages];
  //     updatedMessages.pop();
  //     updatedMessages.push({ text: response, sender: "bot" }); // add actual response
  //     return updatedMessages;
  //   });

  //   setIsBotTyping(false);
  // };

  // ? Handle API Response with Typing Sim
  const handleApiResponse = (response) => {
    let charIndex = 0;

    setMessages((prevMessages) => {
      let updatedMessages = [...prevMessages];
      updatedMessages.pop();
      updatedMessages.push({ text: "", sender: "bot" }); // add actual response
      return updatedMessages;
    });

    const typingInterval = setInterval(() => {
      if (charIndex < response.length) {
        setMessages((prevMessages) => {
          return prevMessages.map((message, index) => {
            if (index === prevMessages.length - 1 && message.sender === "bot") {
              if (charIndex < response.length) {
                message.text += response[charIndex]; // Append the next character
                charIndex++;
              }
            }
            return message;
          });
        });
      } else {
        clearInterval(typingInterval);
        setIsBotTyping(false);
      }
    }, TYPING_SPEED);
  };

  const botRespond = async () => {
    setIsBotTyping(true);

    // Add a loading "..." message for the bot
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: "...", sender: "bot" },
    ]);

    try {
      // Send the API request
      const response = await askQuestion.postQuery({ query: input });

      if (response.ok) {
        // Display the bot's actual response
        handleApiResponse(response.data.answer);
      } else {
        // Display an error message if the response is not OK
        handleApiResponse(ERROR_ANSWER);
      }
    } catch (error) {
      // Handle any API request errors here
      console.error("API request error:", error);

      // Display an error message if the response is not OK
      handleApiResponse(ERROR_ANSWER); // Show generic error to the user
    }
  };

  const handleSend = () => {
    if (input.trim()) {
      setMessages((messages) => [...messages, { text: input, sender: "user" }]);
      setInput("");
      botRespond();
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  return (
    <div className="chatbot-container">
      {isChatOpen ? (
        <div className="chatbot">
          <div className="header">
            <h4>ChatBot</h4>
            <button onClick={toggleChat} className="close">
              <XCircle size={25} />
            </button>
          </div>
          <div className="window">
            {messages.map((message, index) => (
              <div key={index} className="message-container">
                {message.sender === "user" ? (
                  <>
                    <div className={`message ${message.sender}`}>
                      {message.text}
                    </div>
                    <PersonCircle
                      color="var(--primary)"
                      size={20}
                      className="m-1"
                    />
                  </>
                ) : (
                  <>
                    <Robot color="var(--secondary)" size={20} className="m-1" />
                    <div
                      className={`message ${message.sender} ${
                        message.text === "..." ? "loading" : ""
                      }`}
                    >
                      {message.text === "..." ? (
                        <span className="loading-dots"></span>
                      ) : (
                        message.text
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
            <div ref={endOfMessagesRef}></div>
          </div>

          <div className="input-area">
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleInputKeyPress}
              className="input"
              placeholder="Type a message..."
              disabled={isBotTyping}
            />
            <Button onClick={handleSend} variant="primary" className="send-btn">
              <ArrowUp color="white" />
            </Button>
          </div>
        </div>
      ) : (
        <Button
          onClick={toggleChat}
          variant="secondary"
          className="chatbot-open"
        >
          {/*// ? Tested to see if this was the culprit for intese memory usage and its not. */}
          {/* <Image src={chatIcon} alt="Hector Chatbot" className="image" /> */}
          <Image src={chatIcon} alt="Hector Chatbot" className="image" />
          {/* Chat with AI me */}
        </Button>
      )}
    </div>
  );
};

export default ChatBot;
